import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { initialAbilities } from "@/plugins/casl";
import { Employer } from "@/dto/employer/employer";

export const setPermissions = (employer: Employer) => {
  const { can, rules } = new AbilityBuilder(createMongoAbility);
  for (const [key, value] of Object.entries(employer.role.permissions.abilities)) {
    can(value, key);
  }
  initialAbilities.update(rules);
};
