import { useCompanyStore } from "@/store/company";
import { useEmployerStore } from "@/store/employer";
import { type NitroFetchRequest } from "nitropack";

export function $api<T = unknown, R extends NitroFetchRequest = NitroFetchRequest>(
  request: Parameters<typeof $fetch<T, R>>[0],
  options?: Partial<Parameters<typeof $fetch<T, R>>[1]>
) {
  const { company } = storeToRefs(useCompanyStore());
  const { employer } = storeToRefs(useEmployerStore());
  const id = employer.value?.company_id || company.value?.id;

  return $fetch<T, R>(request, {
    ...options,
    headers: id
      ? {
          "x-company-id": id.toString(),
          ...options?.headers,
        }
      : {
          ...options?.headers,
        },
  }).catch((e) => {
    if (e.response?._data?.message) throw createError({ message: e.response._data.message });
    throw e;
  });
}
