import { defineStore } from "pinia";

export const useFetchStateStore = defineStore("fetchState", () => {
  const fetching = ref<{ [key: string]: boolean }>({});
  const fetched = ref<{ [key: string]: boolean }>({});

  const fetchOnce = async (key: string, fetchFunction: () => Promise<any>) => {
    if (fetched.value[key] || fetching.value[key]) return;

    fetching.value = { ...fetching.value, [key]: true };

    try {
      await fetchFunction();
      fetched.value[key] = true;
    } catch (error) {
      throw error;
    } finally {
      fetching.value = { ...fetching.value, [key]: false };
    }
  };

  const clearFetchedState = (key: string) => {
    fetched.value = { ...fetched.value, [key]: false };
  };

  return { fetchOnce, fetching, clearFetchedState };
});
